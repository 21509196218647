import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateBusinessContainer from '@containers/business/crud/CreateBusinessContainer';

import { search as searchBusinesses } from '@api/businessApi';

import * as businessActions from '@actions/businessActions';
import * as navigationActions from '@actions/navigationActions';

let searchTimeout = null;

export const transformBusinesToValue = (business) => ({
  value: business.id,
  label: `${business.name}`,
  business: business,
});

class BusinessesInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      businesses: this.props.value
        ? this.props.value.map((business) => transformBusinesToValue(business))
        : [],
    };
  }

  onChange = (newBusinesses) => {
    this.setState({
      business: newBusinesses,
    });

    const { onChange } = this.props;

    onChange?.(newBusinesses ? newBusinesses.map((newBusines) => newBusines.business) || [] : []);
  };

  render() {
    const { t, isFetchingBusinesses, addToStack } = this.props;
    const { businesses } = this.state;
    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={true}
          isClearable
          cacheOptions
          placeholder={`${t('form.label.selectBusiness')}`}
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) => t('form.addCreatable', { value: inputValue })}
          isLoading={isFetchingBusinesses}
          isDisabled={isFetchingBusinesses}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              searchBusinesses({ query: inputValue }, 0, 40).then((response) => {
                callback(response.businesses.map((business) => transformBusinesToValue(business)));
              });
            }, 800);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('business.new'),
              component: (
                <CreateBusinessContainer
                  targetName={inputValue}
                  callback={(newBusines) => {
                    this.onChange([...businesses, transformBusinesToValue(newBusines)]);
                  }}
                />
              ),
            });
          }}
          value={businesses}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(businessActions.changeCreateBusiness(name, value)),
    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(BusinessesInput));
