import React, { Component, Fragment } from 'react';

import { i18n, store } from '@/index';
import Attributes from '@components/attribute/Attributes';
import BusinessContainer from '@containers/business/BusinessContainer';
import LocationContainer from '@containers/location/LocationContainer';

import BoardField from '@uicomponents/BoardField';
import MapPeek from '@uicomponents/MapPeek';

import * as navigationActions from '@actions/navigationActions';

import { renderActionConstraints } from '@utils/actionUtils';
import { administrativeReferenceToMinimalString } from '@utils/locationUtils';
import { renderEta } from '@utils/tripUtils';
import { isExternal } from '@utils/userUtils';
import { containsPermissionPart } from '@utils/userUtils';

const _renderCustomer = (customer, forceRender = false) => {
  const user = store.getState().auth.user;
  const skipCustomer =
    !forceRender &&
    isExternal(user) &&
    !user?.businesses?.map((b) => b.id).includes(customer?.business?.id);

  if (skipCustomer) return customer?.customer && _renderCustomer(customer.customer, true);

  return (
    <>
      <div>{customer?.business?.name || i18n.t('transportOrder.customer.notFound')}</div>
      {customer?.customer && _renderCustomer(customer.customer, true)}
    </>
  );
};

export const availableFields = [
  {
    name: 'planBoard.info',
    size: 'field--xsmall',
    getLabel: () => '',
    getValue: () => null,
    default: true,
  },
  {
    name: 'transportOrder.orderId',
    size: 'field--median',
    getLabel: () => i18n.t('transportOrder.orderId'),
    getValue: (consignment) => consignment.transportOrder?.orderId,
    default: true,
  },
  {
    name: 'consignment.consignmentNr',
    size: 'field--median',
    getLabel: () => i18n.t('consignment.orderId'),
    getValue: (consignment) => consignment.consignmentNr,
  },
  {
    name: 'transportOrder.status',
    size: 'field',
    getLabel: () => i18n.t('transportOrder.status'),
    getValue: (consignment) => consignment.transportOrder?.status,
    default: true,
  },
  {
    name: 'consignment.status',
    size: 'field',
    getLabel: () => i18n.t('consignment.status'),
    getValue: (consignment) => consignment.status,
  },
  {
    name: 'transportOrder.name',
    size: 'field--big',
    getLabel: () => i18n.t('transportOrder.name'),
    getValue: (consignment) => consignment.transportOrder?.name,
  },
  {
    name: 'transportOrder.referenceDescription',
    size: 'field--median',
    getLabel: () => i18n.t('transportOrder.referenceDescription'),
    getValue: (consignment) => consignment.transportOrder?.referenceDescription,
    default: true,
  },
  {
    name: 'transportOrder.description',
    size: 'field--big',
    getLabel: () => i18n.t('transportOrder.description'),
    getValue: (consignment) => consignment.transportOrder?.description,
  },
  {
    name: 'transportOrder.customer',
    size: 'field--big',
    getLabel: () => i18n.t('transportOrder.customer'),
    divProps: (consignment) => {
      return {
        className: `${consignment.transportOrder?.customer ? 'as-link' : ''}`,
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();

          const business = consignment.transportOrder?.customer?.business;

          store.dispatch(
            navigationActions.addToStack({
              name: business.name,
              className: 'lightgray',
              component: <BusinessContainer business={business} />,
            })
          );
        },
      };
    },
    getValue: (consignment) => _renderCustomer(consignment.transportOrder?.customer),
    default: true,
  },
  {
    name: 'transportOrder.deliveryTerms',
    size: 'field',
    getLabel: () => i18n.t('transportOrder.deliveryTerms'),
    getValue: (consignment) => consignment.transportOrder?.deliveryTerms,
  },
  {
    name: 'consignment.name',
    size: 'field',
    getLabel: () => i18n.t('consignment.name'),
    getValue: (consignment) => consignment.name,
  },

  {
    name: 'consignment.type',
    size: 'field',
    getLabel: () => i18n.t('consignment.type'),
    getValue: (consignment) => consignment.name,
  },
  {
    name: 'consignment.description',
    size: 'field--median',
    getLabel: () => i18n.t('consignment.description'),
    getValue: (consignment) => consignment.description,
  },
  {
    name: 'consignment.currentLocation',
    size: 'field--bigxl',
    getLabel: () => i18n.t('consignment.currentLocation'),
    getValue: (consignment) => {
      return (
        <>
          {consignment?.currentLocation?.lat}, {consignment?.currentLocation?.lon}{' '}
          <MapPeek direction="left" latLonPointGeoReference={consignment?.currentLocation} />
        </>
      );
    },
    default: true,
  },
  {
    name: 'consignment.eta',
    size: 'field--bigxl',
    getLabel: () => i18n.t('consignment.eta'),
    getValue: (consignment) => {
      const lastTrip = [...(consignment?.trips || '')]?.sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate)
      )?.[0];
      return renderEta(
        lastTrip,
        store.getState().settings?.settings?.user?.language?.dateFormat || 'DD/MM/YYYY'
      );
    },
    default: true,
  },
  {
    name: 'consignment.nextAction',
    size: 'field--bigxl',
    getLabel: () => i18n.t('consignment.nextAction'),
    divProps: (consignment) => {
      return {
        className: `${consignment.nextAction ? 'as-link' : ''}`,
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();

          const location = consignment.nextAction?.location?.entity;

          store.dispatch(
            navigationActions.addToStack({
              name: location.name,
              className: 'lightgray',
              component: <LocationContainer location={location} />,
            })
          );
        },
      };
    },
    getValue: (consignment) =>
      consignment.nextAction
        ? administrativeReferenceToMinimalString(
            consignment.nextAction?.location?.entity?.administrativeReference,
            true
          )
        : i18n.t('planBoard.finalStop.empty'),
    default: true,
  },
  {
    name: 'consignment.nextActionTimeConstraints',
    size: 'field--big',
    getLabel: () => i18n.t('consignment.nextActionTimeConstraints'),
    getValue: (consignment) => renderActionConstraints(consignment.nextAction),
  },
  {
    name: 'consignment.nextActionReference',
    size: 'field--big',
    getLabel: () => i18n.t('consignment.nextActionReference'),
    getValue: (consignment) =>
      `${consignment.nextAction?.actions
        ?.filter((a) => a.pin)
        .map((a) => a.pin)
        .join()}`,
  },
  {
    name: 'consignment.nextActionSubTypes',
    size: 'field--big',
    getLabel: () => i18n.t('consignment.nextActionSubTypes'),
    getValue: (consignment) =>
      `${consignment.nextAction?.actions
        .map((a) => a.type)
        .join()}`,
  },
  {
    name: 'consignment.lastAction',
    size: 'field--bigxl',
    getLabel: () => i18n.t('consignment.lastAction'),
    divProps: (consignment) => {
      return {
        className: `${consignment.lastAction ? 'as-link' : ''}`,
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();

          const location = consignment.lastAction?.location?.entity;

          store.dispatch(
            navigationActions.addToStack({
              name: location.name,
              className: 'lightgray',
              component: <LocationContainer location={location} />,
            })
          );
        },
      };
    },
    getValue: (consignment) =>
      consignment.lastAction
        ? administrativeReferenceToMinimalString(
            consignment.lastAction?.location?.entity?.administrativeReference,
            true
          )
        : i18n.t('planBoard.finalStop.empty'),
    default: true,
  },
  {
    name: 'consignment.lastActionTimeConstraints',
    size: 'field--big',
    getLabel: () => i18n.t('consignment.lastActionTimeConstraints'),
    getValue: (consignment) => renderActionConstraints(consignment.lastAction),
  },
  {
    name: 'consignment.lastActionReference',
    size: 'field--big',
    getLabel: () => i18n.t('consignment.lastActionReference'),
    getValue: (consignment) =>
      `${consignment.lastAction?.actions
        ?.filter((a) => a.pin)
        .map((a) => a.pin)
        .join()}`,
  },
  {
    name: 'consignment.lastActionSubTypes',
    size: 'field--big',
    getLabel: () => i18n.t('consignment.lastActionSubTypes'),
    getValue: (consignment) =>
      `${consignment.lastAction?.actions
        .map((a) => a.type)
        .join()}`,
  },
  {
    name: 'consignment.expectedRevenue',
    size: 'field--medium',
    getLabel: () => i18n.t('consignment.expectedRevenue'),
    showLabel: () => containsPermissionPart('ledger'),
    getValue: (consignment) => {
      return (
        <>
          {consignment?.expectedRevenue?.currency?.sign || '€'}
          {consignment?.expectedRevenue?.amount || 0}
        </>
      );
    },
    default: true,
  },
  {
    name: 'consignment.expectedCost',
    size: 'field--medium',
    getLabel: () => i18n.t('consignment.expectedCost'),
    showLabel: () => containsPermissionPart('ledger'),
    getValue: (consignment) => {
      return (
        <>
          {consignment?.expectedCost?.currency?.sign || '€'}
          {consignment?.expectedCost?.amount || 0}
        </>
      );
    },
    default: true,
  },
  {
    name: 'transportEquipment.name',
    level: 'transportEquipment',
    getLabel: (index) => `${i18n.t('transportEquipment')} ${i18n.t('goods.name')} #${index + 1}`,
    getValue: (transportEquipment) => `${transportEquipment.name}`,
    default: true,
  },
  {
    name: 'transportEquipment.description',
    level: 'transportEquipment',
    getLabel: (index) =>
      `${i18n.t('transportEquipment')} ${i18n.t('goods.description')} #${index + 1}`,
    getValue: (transportEquipment) => `${transportEquipment.description}`,
    default: true,
  },
  {
    name: 'transportEquipment.licensePlate',
    level: 'transportEquipment',
    getLabel: (index) =>
      `${i18n.t('transportEquipment')} ${i18n.t('goods.licensePlate')} #${index + 1}`,
    getValue: (transportEquipment) => `${transportEquipment.licensePlate}`,
    default: true,
  },
  {
    name: 'transportEquipment.equipmentId',
    level: 'transportEquipment',
    getLabel: (index) =>
      `${i18n.t('transportEquipment')} ${i18n.t(`goods.equipmentId`)} #${index + 1}`,
    getValue: (transportEquipment) => `${transportEquipment.equipmentId}`,
    default: true,
  },
  {
    name: 'transportEquipment.type',
    level: 'transportEquipment',
    getLabel: (index) => `${i18n.t('transportEquipment')} ${i18n.t('goods.type')} #${index + 1}`,
    getValue: (transportEquipment) => `${transportEquipment.equipmentType}`,
  },
  {
    name: 'transportEquipment.subType',
    level: 'transportEquipment',
    getLabel: (index) => `${i18n.t('transportEquipment')} ${i18n.t(`goods.subType`)} #${index + 1}`,
    getValue: (transportEquipment) => `${transportEquipment.equipmentSubType || ''}`,
    default: true,
  },
  {
    name: 'transportEquipment.seal',
    level: 'transportEquipment',
    getLabel: (index) => `${i18n.t('transportEquipment')} ${i18n.t(`goods.seal`)} #${index + 1}`,
    getValue: (transportEquipment) => `${transportEquipment.seal || ''}`,
  },
  {
    name: 'transportEquipment.quantity',
    level: 'transportEquipment',
    getLabel: (index) =>
      `${i18n.t('transportEquipment')} ${i18n.t('goods.quantity')} #${index + 1}`,
    getValue: (goods) => `${goods.quantity}`,
    default: true,
  },
  {
    name: 'transportEquipment.dimensions',
    level: 'transportEquipment',
    getLabel: (index) =>
      `${i18n.t('transportEquipment')} ${i18n.t('goods.dimensions')} #${index + 1}`,
    getValue: (goods) =>
      goods.length && goods.width
        ? `${goods.length?.value || ''}${goods.length?.unit || ''} x ${goods.width?.value || ''}${
            goods.width?.unit || ''
          } x ${goods.height?.value || ''}${goods.width?.unit || ''}`
        : '',
    default: true,
  },
  {
    name: 'transportEquipment.loadMeters',
    level: 'transportEquipment',
    getLabel: (index) =>
      `${i18n.t('transportEquipment')} ${i18n.t('transportEquipment.loadMeters')} #${index + 1}`,
    getValue: (goods) => `${goods.loadMeter?.value || ''}${goods.loadMeter?.unit || ''}`,
    default: true,
  },
  {
    name: 'transportEquipment.attributes',
    level: 'transportEquipment',
    getLabel: (index) =>
      `${i18n.t('transportEquipment')} ${i18n.t('transportEquipment.attributes')} #${index + 1}`,
    getValue: (goods) => <Attributes attributes={goods.attributes || []} />,
    default: false,
  },
  {
    name: 'item.name',
    level: 'item',
    getLabel: (index) => `${i18n.t('item')} ${i18n.t('goods.name')} #${index + 1}`,
    getValue: (item) => `${item.name}`,
    default: true,
  },
  {
    name: 'item.description',
    level: 'item',
    getLabel: (index) => `${i18n.t('item')} ${i18n.t('goods.description')} #${index + 1}`,
    getValue: (item) => `${item.description}`,
    default: true,
  },
  {
    name: 'item.quantity',
    level: 'item',
    getLabel: (index) => `${i18n.t('item')} ${i18n.t('goods.quantity')} #${index + 1}`,
    getValue: (item) => `${item.quantity}`,
    default: true,
  },
  {
    name: 'item.weight',
    level: 'item',
    getLabel: (index) => `${i18n.t('item')} ${i18n.t('goods.weight')} #${index + 1}`,
    getValue: (item) => `${item.weight?.value || 0}${item.weight?.unit || 'kg'}`,
    default: true,
  },
  {
    name: 'item.grossWeight',
    level: 'item',
    getLabel: (index) => `${i18n.t('item')} ${i18n.t('goods.grossWeight')} #${index + 1}`,
    getValue: (item) => `${item.grossWeight?.value || 0}${item.grossWeight?.unit || 'kg'}`,
    default: true,
  },
  {
    name: 'item.dimensions',
    level: 'item',
    getLabel: (index) => `${i18n.t('item')} ${i18n.t('goods.dimensions')} #${index + 1}`,
    getValue: (goods) =>
      goods.length && goods.width
        ? `${goods.length?.value || ''}${goods.length?.unit || ''} x ${goods.width?.value || ''}${
            goods.width?.unit || ''
          } x ${goods.height?.value || ''}${goods.width?.unit || ''}`
        : '',
    default: true,
  },
  {
    name: 'item.productType',
    level: 'item',
    getLabel: (index) => `${i18n.t('item')} ${i18n.t('goods.productType')} #${index + 1}`,
    getValue: (item) => `${item.productType}`,
  },
  {
    name: 'item.packagingMaterial',
    level: 'item',
    getLabel: (index) => `${i18n.t('item')} ${i18n.t('goods.packagingMaterial')} #${index + 1}`,
    getValue: (item) => `${item.packagingMaterial}`,
  },
  {
    name: 'item.hsCode',
    level: 'item',
    getLabel: (index) => `${i18n.t('item')} ${i18n.t('goods.hsCode')} #${index + 1}`,
    getValue: (item) => `${item.hsCode}`,
  },
  {
    name: 'item.adr',
    level: 'item',
    getLabel: (index) => `${i18n.t('item')} ${i18n.t('goods.adr')} #${index + 1}`,
    getValue: (item) => `${item.ADR ? i18n.t('yes') : i18n.t('no')}`,
  },
];

export default class ConsignmentRow extends Component {
  render() {
    const { consignment, activeFields, settings, index } = this.props;

    const rowIndex = index;
    const showLabels = settings.find((setting) => setting.name == 'showLabels')?.value;
    const showFirstLabelOnly = settings.find(
      (setting) => setting.name == 'showFirstLabelOnly'
    )?.value;

    return (
      <>
        <div className="field field--tiny"></div>

        {availableFields
          ?.filter((field) => {
            const availableField = activeFields.find((af) => af.name === field.name);
            return !field?.level && availableField?.selected;
          })
          ?.map((field) => (
            <BoardField
              key={`consignment-${consignment.id}-${consignment.nonce}-${consignment.updatedAt}-${field.name}`}
              {...field}
              value={consignment}
              showLabel={(rowIndex === 0 && showFirstLabelOnly) || showLabels}
            />
          ))}
        {consignment.goods
          .map((association) => association.entity)
          .filter((good) => good.type === 'transportEquipment')
          .map((transportEquipment, index) => (
            <Fragment
              key={`transportEquipment-${transportEquipment.id}-${transportEquipment.nonce}-${transportEquipment.updatedAt}`}
            >
              {availableFields
                ?.filter((field) => {
                  const availableField = activeFields.find((af) => af.name === field.name);
                  return field?.level === 'transportEquipment' && availableField?.selected;
                })
                .map((field) => (
                  <BoardField
                    key={`transportEquipment-${transportEquipment.id}-${transportEquipment.nonce}-${transportEquipment.updatedAt}-${field.name}`}
                    {...field}
                    value={transportEquipment}
                    index={index}
                    showLabel={(rowIndex === 0 && showFirstLabelOnly) || showLabels}
                  />
                ))}
            </Fragment>
          ))}
        {consignment.goods
          .map((association) => association.entity)
          .filter((good) => good.type === 'items')
          .map((item, index) => (
            <Fragment key={`item-${item.id}-${item.nonce}-${item.updatedAt}`}>
              {availableFields
                ?.filter((field) => {
                  const availableField = activeFields.find((af) => af.name === field.name);
                  return field?.level === 'item' && availableField?.selected;
                })
                .map((field) => (
                  <BoardField
                    key={`item-${item.id}-${item.nonce}-${item.updatedAt}-${field.name}`}
                    {...field}
                    value={item}
                    index={index}
                    showLabel={(rowIndex === 0 && showFirstLabelOnly) || showLabels}
                  />
                ))}
            </Fragment>
          ))}
      </>
    );
  }
}
