import React, { Component } from 'react';

import { isMobile } from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';

import moment from 'moment';

import Loader from '@uicomponents/Loader';
import NoData from '@uicomponents/NoData';
import FormInput from '@uiinputs/FormInput';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

import Avatar from '../ui/components/Avatar';

class ChatsList extends Component {
  constructor(props) {
    super(props);

    this.searchTimeout = null;
    this.searchRef = React.createRef();

    this.state = {
      filters: null,
      searchIsFocused: false,
      searchCaretPosition: {
        start: 0,
        end: 0,
      },
    };
  }

  componentDidMount() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  getPreviewMessage(chat) {
    const { t } = this.props;
    const latestMessage = chat.latestMessage
      ? chat.latestMessage.message
      : t('chat.messages.notFound');

    return latestMessage.length < 45 ? latestMessage : `${latestMessage.substr(0, 45)}...`;
  }

  onScroll(e) {
    const { getChats, searchChats, chatsIsFetching, chatsLoaded, chatsTotalResults } = this.props;
    const { searchQuery } = this.state;
    const bottom = Math.floor(e.target.scrollHeight - e.target.scrollTop) <= e.target.clientHeight;
    if (bottom && chatsLoaded < chatsTotalResults && !chatsIsFetching) {
      searchQuery
        ? searchChats({ query: searchQuery }, chatsLoaded, 20)
        : getChats(chatsLoaded, 20);
    }
  }

  onSearchChangeHandler(e) {
    this.setState({
      searchCaretPosition: {
        start: e.currentTarget.selectionStart,
        end: e.currentTarget.selectionEnd,
      },
    });
  }

  onSearchChange(searchQuery) {
    const { searchChats, getChats } = this.props;
    searchQuery ? searchChats({ query: searchQuery }, 0, 20) : getChats(0, 30);
  }

  _renderChatList() {
    const {
      t,
      i18n,
      chatsIsFetching,
      chatIsFetchingFrom,
      chats,
      activeChat,
      activeChats,
      changeValue,
      openChat,
      chatPopup,
    } = this.props;

    
    if (chats.length > 0 && (!chatsIsFetching || chatIsFetchingFrom > 0)) {
      return (
        <div className="chat-list scrollable" onScroll={(e) => this.onScroll(e)}>
          {chats.map((chat) => (
            <div
              key={chat.id}
              className={`list-item ${activeChat && activeChat.id === chat.id ? ' active' : ''}`}
              onClick={(e) => {
                isMobile && changeValue('maximizeChatList', false);

                if (chatPopup) {
                  if (activeChats && !activeChats.find((item) => item.id === chat.id)) {
                    changeValue('activeChats', [
                      ...activeChats.map((item) => ({
                        ...item,
                        maximize: false,
                      })),
                      { ...chat, maximize: true },
                    ]);
                  }
                } else openChat(chat);
              }}
            >
              <Avatar imageUrl={chat.image.url ?? '/icons/group.svg'} name={chat.title} />
              <div className="details">
                <div className="name">
                  {`${chat.title}` || t('chat.noTitle')}
                  {chat.unreadMessages ? <span>{chat.unreadMessages}</span> : null}
                </div>
                <div
                  className="preview"
                  dangerouslySetInnerHTML={{
                    __html: this.getPreviewMessage(chat),
                  }}
                ></div>
                <div className="preview-date">
                  {chat.latestMessage &&
                    moment(chat.latestMessage.updatedAt)
                      .utc()
                      .local()
                      .locale(i18n.language)
                      .fromNow()}
                </div>
              </div>
            </div>
          ))}
          {chatsIsFetching && <Loader />}
        </div>
      );
    }
    return chatsIsFetching ? <Loader /> : <NoData>{t('chats.notFound')}</NoData>;
  }

  render() {
    const {
      t,
      chatPopup,
      maximizeChatList,
      chatPage,
      changeValue,
      multiChatMessage,
      onMultiChatMessage,
    } = this.props;
    const { searchQuery } = this.state;

    console.log("chatslist", this.props)
    return (
      <div className="aside">
        <div className="chat-header">
          <h2>{t('chats.header')}</h2>
          <div className="icons">
            {(isMobile || chatPopup) && maximizeChatList ? (
              <div
                className="circle-icon-primary circle-md svg-lg"
                onClick={() => changeValue('maximizeChatList', false)}
              >
                <ReactSVG src="/icons/arrow-down.svg" />
              </div>
            ) : !chatPage && !maximizeChatList ? (
              <div
                className="circle-icon-primary circle-md svg-lg"
                onClick={() => changeValue('maximizeChatList', true)}
              >
                <ReactSVG src="/icons/arrow-up.svg" />
              </div>
            ) : null}
            {onMultiChatMessage && (
              <div
                className="circle-icon-primary circle-md svg-lg"
                onClick={() => onMultiChatMessage(true)}
              >
                <ReactSVG src="/icons/create-multi-messages.svg" />
              </div>
            )}
            {chatPopup ? (
              <div
                className="circle-icon-primary circle-md svg-lg"
                onClick={() => changeValue('newChat', true)}
              >
                <ReactSVG src="/icons/edit-new.svg" />
              </div>
            ) : (
              <Link
                to="/messages/new"
                onClick={() => isMobile && changeValue('maximizeChatList', false)}
                className="circle-icon-primary circle-md svg-lg"
              >
                <ReactSVG src="/icons/edit-new.svg" />
              </Link>
            )}
          </div>
        </div>

        {maximizeChatList || (chatPage && (!isMobile || maximizeChatList)) ? (
          <div className="aside-list">
            <FormInput
              ref={this.searchRef}
              type="text"
              value={searchQuery}
              placeholder={t('search.placeholder')}
              onBlur={(e) => {
                this.setState({
                  searchIsFocused: false,
                });
                this.onSearchChangeHandler(e);
              }}
              onChange={(e) => {
                const searchQuery = e.target.value;
                this.onSearchChangeHandler(e);
                this.setState({
                  searchQuery: searchQuery,
                  searchIsFocused: true,
                });
                if (this.searchTimeout) clearTimeout(this.searchTimeout);
                this.searchTimeout = setTimeout(() => {
                  this.onSearchChange(searchQuery);
                }, 400);
              }}
            />
            {this._renderChatList()}
          </div>
        ) : null}
      </div>
    );
  }
}

export default withTranslation('translation')(ChatsList);
