import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

class AlertBadge extends Component {
  render() {
    const { t, alert, addToStack } = this.props;

    return (
      <div className={`alert-badge ${alert.level}`} key={`alert-${alert.id}`}>
        {alert.message}
      </div>
    );
  }
}
export default withTranslation('translation')(AlertBadge);
