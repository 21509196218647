import React, { Component } from 'react';

import Dropzone from 'react-dropzone';
import ReactSVG from 'react-svg';

import * as fileApi from '@api/fileApi';

import { formatBytes } from '@utils/fileUtils';
import { getBase64 } from '@utils/imageUtils';
import { truncate } from '@utils/stringUtils';

import FilePreview from './FilePreview';
import Progress from './Progress';

export default class Attachments extends Component {
  static defaultProps = {
    max: -1,
    accept: {
      'image/png': ['.png', '.jpg'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.oasis.opendocument.text': ['.odt'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'text/csv': ['.csv'],
      'application/xml': ['.xml'],
      'application/json': ['.json'],
      'application/octet-stream': ['.eml', '.msg', '.oml'],
      'application/x-msdownload': ['.eml', '.msg', '.oml'],
      'application/vnd.ms-outlook': ['.msg', '.eml', '.oml'],
    },
  };

  state = {
    files: this.props.files ? this.props.files : [],
  };

  uploadFile = (file) => {
    const { onChange, parseData } = this.props;
    const { files } = this.state;

    const prevFileIndex =
      files.findIndex((f) => f.nonce === file.nonce) >= 0
        ? files.findIndex((f) => f.nonce === file.nonce)
        : 0;

    getBase64(file).then((base64) => {
      fileApi
        .uploadFile(file, 'files', (e) => {
          let newFiles = [...this.state.files];
          let newFile = { ...newFiles[prevFileIndex] };
          newFile.progress = (e.loaded / e.total) * 100;
          newFile.uploading = true;
          if (!newFile.base64) newFile.base64 = base64;

          newFiles[prevFileIndex] = newFile;
          this.setState({
            files: newFiles,
          });
        })
        .then((response) => {
          let newFiles = [...files];
          let newFile = { ...newFiles[prevFileIndex] };
          if (response.success) {
            newFile = { ...response.file, ...newFile };
          } else {
            newFile.error = response.message;
          }
          newFile.uploading = false;
          newFile.uploaded = true;
          newFiles[prevFileIndex] = newFile;

          this.setState(
            {
              files: newFiles,
            },
            () => {
              parseData ? onChange(newFiles, file) : onChange(newFiles);
            }
          );
        });
    });
  };

  render() {
    const { t, max, accept, onChange, isMinimal } = this.props;
    const { files } = this.state;

    return (
      <div className={`upload-files ${isMinimal ? 'd-flex minimum align-items-center' : ''}`}>
        {files.length > 0 &&
          (isMinimal ? (
            <div className="uploaded-files d-flex align-items-center">
              {files.map((file) => {
                return (
                  <div key={`${file.url}-${file.nonce}-${file.uplaoding}-${file.uploaded}`}>
                    <div className="name d-flex font-sm align-items-center text-darkgray">
                      {truncate(
                        file.error ? file.error : file.originalName || file.name || file.path,
                        15
                      )}
                      <div className="actions cursor-pointer align-items-center">
                        {file.uploading && <i className="fa fa-circle-o-notch fa-spin"></i>}
                        {(!file.uploading || file.uploaded) && (
                          <ReactSVG
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.setState(
                                {
                                  files: files.filter((f) => f.id !== file.id),
                                },
                                () => {
                                  onChange(this.state.files);
                                }
                              );
                            }}
                            className="remove-action"
                            src="/icons/cross.svg"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="input-group no-margin-top uploaded-files d-flex gap-20 mt-30">
              {files.map((file) => (
                <div
                  className="file"
                  key={`${file.url}-${file.nonce}-${file.uploading}-${file.uploaded}`}
                >
                  <div
                    className="file-preview"
                    style={{
                      backgroundImage: file.base64 ? `url(${file.base64})` : 'none',
                      backgroundColor: file.error ? '#f8d7da' : '#e0e0e0',
                    }}
                  ></div>
                  <div className="info">
                    <div className="name" title={file.originalName || file.name || file.path}>
                      {truncate(
                        file.error ? file.error : file.originalName || file.name || file.path,
                        10
                      )}
                    </div>
                    <span className="font-xs">{file.size && formatBytes(file.size)}</span>
                    {file.uploaded ? (
                      <div className="state">{t('attachments.uploaded')}</div>
                    ) : (
                      <div className="state">
                        <span>{t('attachments.uploading')}</span>
                        <Progress progress={file.progress || 0} />
                      </div>
                    )}
                  </div>
                  <div className="actions">
                    {file.uploading && <i className="fa fa-circle-o-notch fa-spin"></i>}
                    {(!file.uploading || file.uploaded) && (
                      <ReactSVG
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.setState(
                            {
                              files: files.filter((f) => f.id !== file.id),
                            },
                            () => {
                              onChange(this.state.files);
                            }
                          );
                        }}
                        className="remove-action"
                        src="/icons/cross.svg"
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
        {(max < 0 || files.length < max) && (
          <Dropzone
            ref={this.dropzoneRef}
            style={{ border: 'none' }}
            activeStyle={{ border: 'none' }}
            onDrop={(acceptedFiles) => {
              const files = acceptedFiles.map((file) => {
                file.nonce = Math.random().toString(36).substring(7);
                return file;
              });
              this.setState(
                (prevState) => ({ files: prevState.files.concat(files) }),
                () => {
                  files.forEach((file) => {
                    this.uploadFile(file);
                  });
                }
              );
            }}
            accept={accept}
          >
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
              if (isMinimal) {
                return (
                  <div
                    className={`drag-and-drop${isDragActive ? ' dragging' : ''}${
                      isDragReject ? ' rejected' : ''
                    }`}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <ReactSVG className="attachment-icon" src="/icons/attachment.svg" />
                  </div>
                );
              } else {
                return (
                  <div
                    className={`input-group no-margin-top drag-and-drop${isDragActive ? ' dragging' : ''}${
                      isDragReject ? ' rejected' : ''
                    }`}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <p>
                      {isDragActive
                        ? isDragReject
                          ? t('dragAndDrop.rejected')
                          : t('dragAndDrop.dragging')
                        : t('dragAndDrop.label')}
                    </p>
                  </div>
                );
              }
            }}
          </Dropzone>
        )}
      </div>
    );
  }
}
