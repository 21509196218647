import React, { Component } from 'react';

import { allByEntities, allByEntity } from '@api/alertApi';

import AlertBadge from './AlertBadge';

export default class EntityAlerts extends Component {
  static defaultProps = {
    entityType: '',
    entityId: '',
    entities: [],
  };

  state = {
    alerts: [],
  };

  async componentDidMount() {
    const { entities, entityType, entityId } = this.props;

    if (entities.legnth > 0) {
      allByEntities(entityType, entityId).then((response) => {
        this.setState({
          alerts: response.alerts,
        });
      });
    }

    if (entityType && entityId) {
      allByEntity(entityType, entityId).then((response) => {
        this.setState({
          alerts: response.alerts,
        });
      });
    }
  }

  render() {
    const { alerts } = this.state;

    if (alerts.length > 0) return null;

    return (
      <div className="alert-badges">
        {alerts.map((alert) => (
          <AlertBadge alert={alert} />
        ))}
      </div>
    );
  }
}
