/**
 * get all actions from the consignment
 */
export const getAllActions = (consignment) => {
  let actions = [...consignment.actions];

  consignment.goods.forEach((goods) => {
    getGoodsActions(goods).forEach((action) => actions.push(action));
  });

  return actions;
};

export const getGoodsActions = (goods) => {
  let actions = [...goods.actions];
  goods.containedGoods?.actions?.forEach((action) => {
    actions.push(action);
  });
  return actions;
};

export const updateConsignmentInList = (state, newConsignment) => {
  return [...state.consignments].map((consignment) => {
    if (consignment.id === newConsignment.id) {
      return { ...newConsignment };
    } else return consignment;
  });
};

export const getConsignmentStatus = (consignment) => {
  let status = 'watchTower__status';

  switch (consignment.status) {
    case 'draft':
    default:
      status += '--draft';
      break;
    case 'confirmed':
      status += '--confirmed';
      break;
    case 'in_transit':
      status += '--in-transit';
      break;
    case 'completed':
      status += '--completed';
      break;
    case 'cancelled':
      status += '--cancelled';
      break;
    case 'partially_planned':
      status += '--partially-planned';
      break;
    case 'planned':
      status += '--planned';
      break;
  }

  return status;
};

/**
 * get all actions from the consignment
 */
export const getAllGoods = (consignment) => {
  let goods = [...consignment.goods];

  goods.forEach((goods) => {
    goods.containedGoods?.forEach((innerGoods) => goods.push(innerGoods));
  });

  return goods.map((association) => association.entity);
};
