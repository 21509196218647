import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import SimpleReactValidator from 'simple-react-validator';

import ActionsInput from '@components/action/crud/ActionsInput';
import ConstraintsInput from '@components/constraint/crud/ConstraintsInput';
import ADRInput from '@components/general/crud/ADRInput';
import DimensionsInput from '@components/ui/inputs/DimensionsInput';
import ValueWithUnitInput from '@components/ui/inputs/ValueWithUnitInput';

import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

class ItemsForm extends Component {
  static defaultProps = {
    flat: false,
  };
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      goods: this.props.goods,
      sequenceNr: this.props.initialSequenceNr - (this.props.goods?.actions?.length || 0),
    };
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate(prevProps) {
    activateInputs();

    if (prevProps.initialSequenceNr !== this.props.initialSequenceNr) {
      this.setState({
        sequenceNr: this.props.initialSequenceNr - (this.props.goods?.actions?.length - 0),
      });
    }
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  onChange = (newGoods) => {
    this.setState({
      goods: newGoods,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { onChange, popStack } = this.props;
    const { goods } = this.state;

    onChange?.(goods);
    popStack?.();
  };

  render() {
    const { t } = this.props;
    const { goods, sequenceNr } = this.state;

    return (
      <>
        <div>
          <FormInput
            type="text"
            label="form.label.name"
            value={goods.name}
            required={true}
            onChange={(event) => {
              const newGoods = { ...goods };
              newGoods.name = event.target.value;

              this.onChange(newGoods);
            }}
          />
          {this.validator.message(t('form.label.name'), goods.name, 'required')}
        </div>
        <FormInput
          type="text"
          label="form.label.barCode"
          value={goods.barCode}
          onChange={(event) => {
            const newGoods = { ...goods };
            newGoods.barCode = event.target.value;

            this.onChange(newGoods);
          }}
        />
        <FormInput
          type="text"
          label="form.label.hscode"
          value={goods.hscode}
          onChange={(event) => {
            const newGoods = { ...goods };
            newGoods.hscode = event.target.value;

            this.onChange(newGoods);
          }}
        />
        <FormInput
          type="number"
          label="form.label.quantity"
          value={goods.quantity}
          onChange={(event) => {
            const newGoods = { ...goods };
            newGoods.quantity = event.target.value;

            this.onChange(newGoods);
          }}
        />
        <FormInput
          type="textarea"
          label="form.label.description"
          className="small"
          value={goods.description}
          onChange={(event) => {
            const newGoods = { ...goods };
            newGoods.description = event.target.value;

            this.onChange(newGoods);
          }}
        />
        <FormInput
          type="text"
          label="form.label.productType"
          value={goods.productType}
          onChange={(event) => {
            const newGoods = { ...goods };
            newGoods.productType = event.target.value;

            this.onChange(newGoods);
          }}
        />
        <FormInput
          type="text"
          label="form.label.packagingMaterial"
          value={goods.packagingMaterial}
          onChange={(event) => {
            const newGoods = { ...goods };
            newGoods.packagingMaterial = event.target.value;

            this.onChange(newGoods);
          }}
        />
        <ValueWithUnitInput
          t={t}
          units={['g', 'kg', 'lbs', 't']}
          defaultUnit={'kg'}
          label="form.label.weight"
          value={goods.weight}
          onChange={(value) => {
            const newGoods = { ...goods };
            newGoods.weight = value;

            this.onChange(newGoods);
          }}
        />
        <ValueWithUnitInput
          t={t}
          units={['g', 'kg', 'lbs', 't']}
          defaultUnit={'kg'}
          label="form.label.grossWeight"
          value={goods.grossWeight}
          onChange={(value) => {
            const newGoods = { ...goods };
            newGoods.grossWeight = value;

            this.onChange(newGoods);
          }}
        />
        <ValueWithUnitInput
          t={t}
          units={['g', 'kg', 'lbs', 't']}
          defaultUnit={'kg'}
          label="form.label.maxStackableWeight"
          value={goods.maxStackableWeight}
          onChange={(value) => {
            const newGoods = { ...goods };
            newGoods.maxStackableWeight = value;

            this.onChange(newGoods);
          }}
        />

        <DimensionsInput
          key={goods.id + goods.nonce}
          defaultUnit="cm"
          unit={goods.length ? goods.length.unit : null}
          length={goods.length}
          height={goods.height}
          width={goods.width}
          onChange={(e) => {
            const newGoods = { ...goods };
            newGoods.length = { value: e.length, unit: e.unit };
            newGoods.height = { value: e.height, unit: e.unit };
            newGoods.width = { value: e.width, unit: e.unit };

            this.onChange(newGoods);
          }}
        />
        <div className="input-group">
          <div>{t('form.label.ADR')}</div>
          <Switch
            checked={goods.adr ? true : false}
            onChange={(e, newValue) => {
              const newGoods = { ...goods };
              newGoods.adr = newValue ? { language: 'English' } : undefined;

              this.onChange(newGoods);
            }}
          />
        </div>
        {goods.adr ? (
          <div className="input-group list-sector">
            <h2>{t('form.label.ADR')}</h2>
            <ADRInput
              ADR={goods.adr}
              onChange={(newADR) => {
                const newGoods = { ...goods };
                newGoods.adr = newADR;

                this.onChange(newGoods);
              }}
            />
          </div>
        ) : null}
        <div className="input-group no-margin-top">
          <h3>{t('form.label.actions')}</h3>
          <ActionsInput
            {...this.props}
            actions={goods?.actions}
            enabledActionTypes={['load', 'unload']}
            initialSequenceNr={sequenceNr + (goods?.actions?.length || 0)}
            onChange={(actions) => {
              const newGoods = { ...goods };
              newGoods.actions = actions;

              this.onChange(newGoods);
            }}
          />
        </div>
        <div className="input-group no-margin-top">
          <h3>{t('form.label.constraints')}</h3>
          <ConstraintsInput
            {...this.props}
            constraints={goods.constraints}
            onChange={(constraints) => {
              const newGoods = { ...goods };
              newGoods.constraints = constraints;

              this.onChange(newGoods);
            }}
          />
        </div>
        <div className="input-group more right">
          <input
            type="submit"
            disabled={!this.validator.allValid()}
            onClick={(e) => this.handleSubmit(e)}
            value={t('form.save')}
          />
        </div>
      </>
    );
  }
}
export default withTranslation('translation')(ItemsForm);
