import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import TransportOrder from '@models/transportOrder/TransportOrder';

import TransportOrderForm from './TransportOrderForm';

export default class CreateTransportOrderPage extends Component {
  componentDidMount() {
    const { onChange, targetTransportOrder, userInterfaceSettings, platform } = this.props;

    const newTransportOrder = targetTransportOrder
      ? { ...targetTransportOrder }
      : userInterfaceSettings?.defaultTransportOrderTemplate
      ? JSON.parse(userInterfaceSettings?.defaultTransportOrderTemplate)
      : platform.planningSettings?.defaultTransportOrderTemplate
      ? JSON.parse(platform.planningSettings?.defaultTransportOrderTemplate)
      : new TransportOrder();

    if (!newTransportOrder.deliveryTerms) {
      newTransportOrder.deliveryTerms = platform.planningSettings?.defaultDeliveryTerms;
    }

    onChange('formTransportOrder', newTransportOrder);
  }

  render() {
    const { t, flat, formTransportOrder, isFetchingTransportOrder, createTransportOrder } =
      this.props;

    if (!formTransportOrder || isFetchingTransportOrder) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('transportOrder.new')}</h1>
        <TransportOrderForm
          {...this.props}
          flat={flat}
          key={formTransportOrder.id}
          onSubmit={(transportOrder) => createTransportOrder(transportOrder)}
        />
      </AsideView>
    );
  }
}
