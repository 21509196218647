import React, { Component, Suspense, lazy } from 'react';

import { withTranslation } from 'react-i18next';
import ScrollContainer from 'react-indiana-drag-scroll';

import EntityTodosContainer from '@containers/todo/EntityTodosContainer';

import Collapsible from '@uicomponents/Collapsible';
import InformationComponent from '@uicomponents/InformationComponent';
import Loader from '@uicomponents/Loader';

import ActionCards from '../action/ActionCards';
import ADRView from '../general/ADRView';
import GoodsView from './GoodsView';

const TiresContainer = lazy(() => import('@containers/tire/TiresContainer'));

class TransportEquipmentView extends Component {
  static defaultProps = {
    small: false,
  };
  constructor(props) {
    super(props);

    this.state = {
      tab: 'transportEquipment',
    };
  }

  render() {
    const { tab } = this.state;
    const { t, small, goods, simplifiedView } = this.props;

    const fieldsToRender = [
      { name: 'equipmentType', type: 'equipmentType', entityType: 'goods' },
      { name: 'equipmentSubType', type: 'text', entityType: 'goods' },
      { name: 'quantity', type: 'text', entityType: 'goods' },
      { name: 'weight', type: 'weight', entityType: 'goods' },
      { name: 'grossWeight', type: 'weight', entityType: 'goods' },
      { name: 'loadMeters', type: 'loadMeters', entityType: 'goods' },
      { name: 'dimensions', type: 'dimensions', entityType: 'goods' },
      { name: 'description', type: 'text', entityType: 'goods' },
      { name: 'licensePlate', type: 'text', entityType: 'goods' },
      { name: 'equipmentId', type: 'text', entityType: 'goods' },
      { name: 'seal', type: 'text', entityType: 'goods' },
      { name: 'attributes', type: 'attributes', entityType: 'goods' },
    ];
    return (
      <div className="goods-view">
        {small ? (
          <h2 className="no-margin">{goods.name}</h2>
        ) : (
          <h1 className="no-margin">{goods.name}</h1>
        )}
        <p className="no-margin-top no-margin-bottom">#{goods.id}</p>

        {!simplifiedView && (
          <ScrollContainer
            verticle={false}
            hideScrollbars={false}
            className="align-items-center d-flex gap-10 tab-links-container my-1"
          >
            <div
              className={`tab-links${tab === 'transportEquipment' ? ' tab-active' : ''}`}
              onClick={() => this.setState({ tab: 'transportEquipment' })}
            >
              {t('transportEquipments.header')}
            </div>
            <div
              className={`tab-links${tab === 'tires' ? ' tab-active' : ''}`}
              onClick={() => this.setState({ tab: 'tires' })}
            >
              {t('tires.header')}
            </div>
            {this.props.platform?.features?.filter((feature) => feature.name === 'todo').length >
              0 && (
              <div
                className={`tab-links${tab === 'todos' ? ' tab-active' : ''}`}
                onClick={() => this.setState({ tab: 'todos' })}
              >
                {t('todos.header')}
              </div>
            )}
          </ScrollContainer>
        )}

        {simplifiedView ? (
          <>
            <InformationComponent entity={goods} fields={fieldsToRender} />
            {goods.adr ? (
              <>
                <div>
                  <b>{t('adr.header')}</b>
                </div>
                <ADRView adr={goods.adr} />
              </>
            ) : null}
            <ActionCards active={false} actions={goods.actions} />
            <div className="goods">
              {goods.containedGoods &&
                goods.containedGoods
                  ?.map((association) => association.entity)
                  ?.filter((entity) => entity.type === 'items')
                  ?.map((entity, index) => (
                    <Collapsible
                      key={entity.id}
                      name={`${t('goods.header', { count: index + 1 })} ${
                        entity.name ? ` - ${entity.quantity}x ${entity.name}` : null
                      }`}
                      className="collapsible-element reversed"
                      defaultIsOpen={false}
                      childStyle={{ padding: '10px 10px 10px 40px' }}
                    >
                      <GoodsView key={entity.id} goods={entity} />
                    </Collapsible>
                  ))}
            </div>
            <div className="goods">
              {goods.containedGoods
                ?.map((association) => association.entity)
                ?.filter((entity) => entity.type === 'transportEquipment')
                ?.map((entity, index) => (
                  <Collapsible
                    key={entity.id}
                    name={`${t('transportEquipment.header', {
                      count: index + 1,
                    })} ${entity.name ? ` - ${entity.name}` : null}`}
                    className="collapsible-element reversed"
                    defaultIsOpen={false}
                    childStyle={{ padding: '10px 10px 10px 40px' }}
                  >
                    <GoodsView key={entity.id} goods={entity} />
                  </Collapsible>
                ))}
            </div>
          </>
        ) : (
          <>
            <section className={`vehicle-detail__box activity${tab === 'tires' ? ' active' : ''}`}>
              {tab === 'tires' ? (
                <Suspense fallback={<>{<Loader />}</>}>
                  <TiresContainer entityType="transportEquipment" entity={goods} />
                </Suspense>
              ) : null}
            </section>

            <section className={`chauffeur-view__box activity${tab === 'todos' ? ' active' : ''}`}>
              {tab === 'todos' ? (
                <EntityTodosContainer
                  {...this.props}
                  relatedEntity={goods}
                  relatedEntityType="transportEquipment"
                />
              ) : null}
            </section>

            <section
              className={`vehicle-detail__box activity${
                tab === 'transportEquipment' ? ' active' : ''
              }`}
            >
              {tab === 'transportEquipment' ? (
                <>
                  <InformationComponent entity={goods} fields={fieldsToRender} />
                  {goods.adr ? (
                    <>
                      <div>
                        <b>{t('adr.header')}</b>
                      </div>
                      <ADRView adr={goods.adr} />
                    </>
                  ) : null}
                  <ActionCards active={false} actions={goods.actions} />
                  <div className="goods">
                    {goods.containedGoods &&
                      goods.containedGoods
                        ?.map((association) => association.entity)
                        ?.filter((entity) => entity.type === 'items')
                        ?.map((entity, index) => (
                          <Collapsible
                            key={entity.id}
                            name={`${t('goods.header', { count: index + 1 })} ${
                              entity.name ? ` - ${entity.quantity}x ${entity.name}` : null
                            }`}
                            className="collapsible-element reversed"
                            defaultIsOpen={false}
                            childStyle={{ padding: '10px 10px 10px 40px' }}
                          >
                            <GoodsView key={entity.id} goods={entity} />
                          </Collapsible>
                        ))}
                  </div>
                  <div className="goods">
                    {goods.containedGoods
                      ?.map((association) => association.entity)
                      ?.filter((entity) => entity.type === 'transportEquipment')
                      ?.map((entity, index) => (
                        <Collapsible
                          key={entity.id}
                          name={`${t('transportEquipment.header', {
                            count: index + 1,
                          })} ${entity.name ? ` - ${entity.name}` : null}`}
                          className="collapsible-element reversed"
                          defaultIsOpen={false}
                          childStyle={{ padding: '10px 10px 10px 40px' }}
                        >
                          <GoodsView key={entity.id} goods={entity} />
                        </Collapsible>
                      ))}
                  </div>
                </>
              ) : null}
            </section>
          </>
        )}
      </div>
    );
  }
}
export default withTranslation('translation')(TransportEquipmentView);
